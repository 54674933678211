import "./App.css";
import './assets/css/analys360.css'
import './assets/css/mainComponents.css'
import './assets/css/hausrat.css'
import './assets/css/krankenkasse.css'
import './assets/css/pension.css'
import './assets/css/plus.css'
import './assets/css/uberUns.css'
import './assets/css/versicherungen.css'

import { BrowserRouter, Route, Routes } from "react-router-dom";
import React, { lazy, Suspense } from 'react'

import MoonLoader from "react-spinners/ClipLoader";

const Header = lazy(() => import('./Header'))

const PensionPlan = lazy(() => import('./components/PensionPlan'))
const ScrollTop = lazy(() => import('./ScrollTop.js'))

function App() {

  return (
    <div className="App">
      <>
        <BrowserRouter>
          <ScrollTop>
            <Suspense fallback={
              <div style={{ width: '100%', height: '100vh', display: 'grid', placeItems: 'center' }}>
                <MoonLoader size={100} color="#30313A" />
              </div>
            }>
              <Header />
              <Routes>
                <Route exact path="/" element={<PensionPlan />} />
              </Routes>
              {/* <Footer /> */}
            </Suspense>
          </ScrollTop>
        </BrowserRouter>
      </>
    </div>
  );
}

export default App;
